<template>
<div class="wrapper event-details buffet-view">

        <div class="content">
            <div class="left">
                <div class="headline">
                    <h1>{{ buffet.title }}</h1>
                    <h6>{{ $t('buffet-title') }}</h6>
                </div>

                <div class="food-content-list" v-if="buffet.buffetFoods">
                    <div class="package" v-if="featuredFoods.length > 0">
                        <h3 id="featured">{{ $t('buffet-featured-products') }}</h3>

                        <food-item @open-details="openModal"
                        :foodData="row.food"
                        v-for="(row, fkey) in featuredFoods" :key="'food-'+fkey"></food-item>
                    </div>
                    <div class="package" v-if="normalFoods.length > 0">
                        <h3 id="more">{{ $t('buffet-more-products') }}</h3>

                        <food-item @open-details="openModal"
                        :foodData="row.food"
                        v-for="(row, fkey) in normalFoods" :key="'food-'+fkey"></food-item>
                    </div>
                </div>
            </div>

            <div class="right">
                <cart :isPreorder="false"></cart>
            </div>
        </div>

        <vue-final-modal v-model="modalOpened">
            <div class="modal-product" v-if="selectedProduct">
                <div class="close-btn">
                    <div class="circle">
                        <button @click="closeModal()">
                            <font-awesome-icon icon="fa-solid fa-xmark" />
                        </button>
                    </div>
                </div>
                <div class="image">
                    <smart-image :img_key="selectedProduct.image_key" :size="'medium'"></smart-image>
                </div>
                <div class="content">
                    <div class="name">
                        <strong>{{ selectedProduct.title }}</strong>
                    </div>
                    <div class="price">
                        {{$n(getGrossPrice(selectedProduct), 'currency')}}
                    </div>

                    <div class="description" v-html="productModalLead"></div>
                </div>
                <div class="bottom">
                    <div class="qtyButtons">
                        <button @click="changeQty('minus')">
                            <font-awesome-icon icon="fa-solid fa-minus" />
                        </button>
                        <div class="num">{{ quantity }}</div>
                        <button @click="changeQty('plus')">
                            <font-awesome-icon icon="fa-solid fa-plus" />
                        </button>
                    </div>
                    <div class="addToCart">
                        <button @click="addToCart()">
                            {{ $t('add-to-cart') }} <span class="arrow"></span>
                        </button>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
import Cart from '../../components/skybox/cart/cart.vue'
import { VueFinalModal } from 'vue-final-modal'
import SmartImage from "../../components/smart-image.vue";
import {getBuffetById} from '../../functions/api/content';
import foodItem from "../../components/skybox/food-item.vue"
import store from '../../store';
import { marked } from 'marked';

export default {
    components: { Cart, foodItem, VueFinalModal, SmartImage },
    data() {
        return {
            buffet: {
                buffetFoods: []
            },
            id: -1,
            modalOpened: false,
            quantity: 1,
            selectedProduct: false,
        }
    },
    async created() {
        await this.refreshBuffet(store.state.cart.buffetId);

        this.$store.commit('setSkyboxTitle', this.buffet.title)
        this.$store.commit('setSkyboxText', '')
        this.$store.commit('setSkyboxLink', '/buffet-list')
        this.$store.commit('setSkyboxLinkText', 'buffet-view-back')
    },
    beforeRouteEnter(to, from, next) {
        if(!store.state.cart.buffetId) {
            return next({name: 'buffet-list'});
        }
        next();
    },
    computed: {
        featuredFoods() {
            return this.buffet.buffetFoods ? this.buffet.buffetFoods.filter(bf => bf.featured) : [];
        },
        normalFoods() {
            return this.buffet.buffetFoods ? this.buffet.buffetFoods.filter(bf => !bf.featured) : [];
        },
        productModalLead() {
            if(this.selectedProduct) {
                return marked.parse(this.selectedProduct.lead);
            }

            return '';
        }
    },
    methods: {
        async refreshBuffet(id) {
            return new Promise((resolve, reject) => {
                this.loading = true;
                return getBuffetById(id)
                .then(res => {
                    this.buffet = res;
                    this.loading = false;
                    this.$store.dispatch
                    resolve(res);
                })
                .catch(err => {
                    this.loading = false;
                    reject(err);
                });
            });
        },
        openModal(product) {
            console.log(product);
            this.modalOpened = true;
            this.selectedProduct = product;
        },
        closeModal() {
            this.modalOpened = false;
            this.selectedProduct = false;
            this.quantity = 1;
        },
        changeQty(way) {
            switch(way) {
                case 'plus':
                    this.quantity++;
                break;

                case 'minus':
                    if(this.quantity > 1) {
                        this.quantity--;
                    }
                break;
            }
        },
        addToCart() {
            let quantity = this.quantity;
            this.$store.dispatch('updateProductQuantityInCart', {product: this.selectedProduct, quantity});

            this.closeModal();
        },
        getGrossPrice(product) {
            return product.price;
        }
    }
}
</script>